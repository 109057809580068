var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"skills"}},[_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex align-start flex-column"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"title text-uppercase black--text mb-3",style:(_vm.$vuetify.breakpoint.mdAndUp
                    ? 'padding-top:100px'
                    : 'padding-top:50px')},[_vm._v("software skills")]),_c('span',{class:[
                  'mb-3',
                  'text-justify',
                  _vm.$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2'
                ]},[_vm._v(" I strongly believe that professional knowledge of software development should go beyond learning tools and technologies. Mastering best practices and adopting a pragmatic approach throughout the full software development process is what guarantees efficiency and productivity. ")])]),_c('v-list-item-group',{style:(_vm.$vuetify.breakpoint.xlAndUp
                  ? 'width:80%'
                  : _vm.$vuetify.breakpoint.lgAndUp
                  ? 'width:90%'
                  : 'width:100%')},[_vm._l((_vm.softwareSkills),function(item,index){return [_c('v-list-item',{key:index,class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-0' : ''},[_c('v-list-item-avatar',{attrs:{"size":"70"}},[_c('v-img',{attrs:{"contain":"","height":"42","src":item.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"subtitle-1 primary--text",staticStyle:{"font-family":"Francois One !important"}},[_vm._v(_vm._s(item.headline))])]),_c('v-list-item-subtitle',[_c('v-rating',{attrs:{"length":item.length,"readonly":"","color":"primary","background-color":"primary","full-icon":"mdi-checkbox-blank-circle","empty-icon":"mdi-checkbox-blank-circle-outline","size":_vm.$vuetify.breakpoint.smAndDown ? 13 : 20},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)],1)],1),(index + 1 < _vm.softwareSkills.length)?_c('v-divider',{key:item.headline,attrs:{"inset":""}}):_vm._e()]})],2)],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"title text-uppercase black--text mb-3",style:(_vm.$vuetify.breakpoint.mdAndUp
                    ? 'padding-top:100px'
                    : 'padding-top:50px')},[_vm._v("Analytical Skills")]),_c('span',{class:[
                  'mb-3',
                  'text-justify',
                  _vm.$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2'
                ]},[_vm._v(" In mastering analytical tools, my approach is mainly based on three principles: (1) understandng the type of problems a tool can solve within a domain, (2) the assumptions behind the tool, and (3) illustrating the pros and cons of the tool. ")])]),_c('v-list-item-group',{style:(_vm.$vuetify.breakpoint.xlAndUp
                  ? 'width:80%'
                  : _vm.$vuetify.breakpoint.lgAndUp
                  ? 'width:90%'
                  : 'width:100%')},[_vm._l((_vm.analyticalSkills),function(item,index){return [_c('v-list-item',{key:index,class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-0' : ''},[_c('v-list-item-avatar',{attrs:{"size":"70"}},[_c('v-img',{attrs:{"contain":"","height":"50","src":item.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"subtitle-1 primary--text",staticStyle:{"font-family":"Francois One !important"}},[_vm._v(_vm._s(item.headline))])]),_c('v-list-item-subtitle',[_c('v-rating',{attrs:{"length":item.length,"readonly":"","color":"primary","background-color":"primary","full-icon":"mdi-checkbox-blank-circle","empty-icon":"mdi-checkbox-blank-circle-outline","size":_vm.$vuetify.breakpoint.smAndDown ? 10 : 20},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)],1)],1),(index + 1 < _vm.analyticalSkills.length)?_c('v-divider',{key:item.headline,attrs:{"inset":""}}):_vm._e()]})],2)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }